import { useRef, useState } from 'react';
import styles from './CartStatic.module.scss';
import CartItem from '../CartItem';
import Convert from '../Convert';
import { useSelector, useDispatch } from 'react-redux';
import { setPromoSale } from '../../redux/slices/cardSlice';
import { setPromoName } from '../../redux/slices/userSlice';
import { useNavigate } from 'react-router-dom';
import { promocodes } from '../../utils/promocodes';
import { useTranslation } from 'react-i18next';

export default function CartStatic({ popup }) {
  const { t } = useTranslation();

  const [promo, setPromo] = useState('');
  const [errorPromo, setErrorPromo] = useState(null);
  const dispatch = useDispatch();

  const total = useSelector((state) => state.card.total);
  const finalTotal = useSelector((state) => state.card.finalTotal);
  const totalWithoutPromo = useSelector(
    (state) => state.card.totalWithoutPromo
  );
  const promoSale = useSelector((state) => state.card.promoSale);
  const items = useSelector((state) => state.card.items);

  const cartWindow = useRef();
  const navigate = useNavigate();

  const toCheckout = () => {
    if (total > 0) navigate('/checkout');
  };

  const validPromo = () => {
    const res = promocodes.find(
      (obj) => obj.name.toLowerCase() === promo.toLowerCase()
    );

    if (res) {
      dispatch(setPromoSale(res.value));
      dispatch(setPromoName(res.name));
      setErrorPromo(null);
    } else {
      dispatch(setPromoSale(0));
      dispatch(setPromoName(''));
      setErrorPromo(t("Promo doesn't exist!"));
    }
  };

  return (
    <div
      className={
        popup === true
          ? `${styles.cart} ${styles.cartAbsolut}`
          : `${styles.cart}`
      }
      ref={cartWindow}
    >
      <p className={styles.cart_title}>{t('Cart')}</p>
      <div className={styles.cart_container}>
        <div className={styles.cart_containerInner}>
          {items.length > 0 ? (
            items.map((obj) => <CartItem {...obj} key={obj.cartItemId} />)
          ) : (
            <p className={styles.emptyCart}>
              <img src="/img/empty.svg" alt="" /> {t('Your cart is empty')}
            </p>
          )}
        </div>
      </div>
      <div className={styles.promo}>
        <input
          type="text"
          placeholder={t('Enter your promo code')}
          onChange={(e) => setPromo(e.target.value)}
        />
        <img src="/img/promo.svg" alt="" onClick={validPromo} />
        {errorPromo && <span className="error">{errorPromo}</span>}
      </div>
      <div className={styles.total}>
        <span>{t('Total without promo')}</span>
        <span>
          <Convert usdPrice={total} />
          {/* <Convert usdPrice={promoSale > 0 ? totalWithoutPromo : total} /> */}
        </span>
      </div>
      {promoSale > 0 && (
        <div className={styles.total}>
          <span>{t(`Promo -${promoSale}%`)}</span>
          <span>
            <Convert usdPrice={finalTotal} />
          </span>
        </div>
      )}
    </div>
  );
}
