import {useState} from 'react'
import styles from "./PaymentOfferta.module.scss"
import { useEffect } from 'react'
import ReactPixel from 'react-facebook-pixel';
import { useTranslation  } from 'react-i18next';
import axios from "axios";
import {Helmet} from "react-helmet";


export default function PaymentOfferta() {
  const [title, setTitle] = useState('');
  const [descr, setDescr] = useState('');
  const [seoText, setSeoText] = useState('');
  const { i18n } = useTranslation();


  const getApiCats = async(id) => {
    console.log(id);
    await axios('https://api.sicvolo.org/wp-json/wp/v2/pages?per_page=100')
    .then(res =>{
      console.log(res);
      const data = res.data;
      console.log(data);
      const apiCat = data?.filter((obj) => obj.id === 1948);
      console.log(apiCat);

      if(i18n.language === "en"){
        setTitle(apiCat[0].acf.title_en);
        setDescr(apiCat[0].acf.description_en);
        setSeoText(apiCat[0].acf.seo_text_en);
      }
      if(i18n.language === "sp"){
        setTitle(apiCat[0].acf.title_sp);
        setDescr(apiCat[0].acf.description_sp);
        setSeoText(apiCat[0].acf.seo_text_sp);
      }
      if(i18n.language === "fr"){
        setTitle(apiCat[0].acf.title_fr);
        setDescr(apiCat[0].acf.description_fr);
        setSeoText(apiCat[0].acf.seo_text_fr);
      }
      if(i18n.language === "it"){
        setTitle(apiCat[0].acf.title_it);
        setDescr(apiCat[0].acf.description_it);
        setSeoText(apiCat[0].acf.seo_text_it);
      }
      if(i18n.language === "ua"){
        setTitle(apiCat[0].acf.title_ua);
        setDescr(apiCat[0].acf.description_ua);
        setSeoText(apiCat[0].acf.seo_text_ua);
      }
      if(i18n.language === "ru"){
        setTitle(apiCat[0].acf.title_ru);
        setDescr(apiCat[0].acf.description_ru);
        setSeoText(apiCat[0].acf.seo_text_ru);
      }
       
    } )
   
  }


  useEffect(()=>{
    getApiCats();
    if(window.fbq){
      window.fbq('track', 'ViewContent', { 
          content_type: 'Payment and Shipping page',
          content_ids: ['1234'],
          content_name: 'Payment and Shipping page',
          content_category: 'Payment and Shipping page',
         
        })
      window.fbq('track', 'PageView')
    }
    
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
     <Helmet>
        <link rel="canonical" href={`https://sicvolo.com${window.location.pathname}`} />
            <title>{title}</title> 
            <meta name="description" content={descr} />
            <meta property="og:title" content={title} />
            <meta property="og:image" content="https://sicvolo.com/img/sl2.png" />
            <meta property="og:description" content={descr} />
        </Helmet>
        

        <div className={styles.policy_wrapper}>
      {i18n.language === "ua" && (<>
        <h1 >Договір публічної оферти</h1>
      <p>Інтернет-магазині ювелірних прикрас та аксесуарів для одягу SICVOLO (далі «Продавець»), електронна адреса www.sicvolo.com, ви (далі «Покупець») погоджуєтесь на наступне:</p>
    


      <div className={styles.policy_item}>
        <p className={styles.policy_item_title}>1. Загальні положення</p>
        <div className={styles.policy_item_text}>
            <p>1.1. Здійснюючи замовлення в інтернет-магазині Продавця, Покупець приймає і зобов'язується дотримуватися умов, викладених в цій Угоді.</p>
            <p>1.2. Ця Угода є публічною офертою.</p>
            <p>1.3. Покупець, оформляючи замовлення на сайті SICVOLO.COM, підтверджує та погоджується на умови цієї Угоди.</p>
            <p>1.4. Дана Угода може бути змінена в односторонньому порядку Продавцем повністю або частково в будь-який час без будь-якого окремого повідомлення. Нова редакція Угоди вступить в силу з моменту опублікування нової редакції на сайті.</p>
            <p>1.5. Інтернет-магазин SICVOLO.COM містить об'єкти інтелектуальної власності: фотографії, відеоматеріали, графічні зображення, ексклюзивний дизайн ювелірних виробів. Покупець погоджується не вчиняти дії, які порушують чинне законодавство України, в тому числі в сфері інтелектуальної власності, авторських і / або суміжних прав, а також будь-яких інших дій, які призводять або можуть призвести до порушення нормальної роботи сайту та сервісів сайту.</p>
            <p>1.6. Всі торгові марки і назви, на які даються посилання в матеріалах цього сайту, є власністю Продавця.</p>
            <p>1.7. . Покупець, якому не виповнилося 18 років, необхідно отримати згоду батьків або інших законних представників на оформлення замовлення. Якщо батьки або інші законні представники Покупця не згодні з будь-якою умовою цієї Угоди, Покупець зобов'язаний відмовитися від оформлення замовлення на сайті Продавця.</p>
        </div>
      </div>

      <div className={styles.policy_item}>
        <p className={styles.policy_item_title}>2. Умови здійснення замовлення товару</p>
        <div className={styles.policy_item_text}>
            <p>2.1. Покупець здійснює вибір ювелірних виробів або аксесуарів для одягу (Товар) з каталогу SICVOLO.COM, оформляє Замовлення шляхом заповнення електронної форми на сайті і направляє його Продавцю.</p>
            <p>2.2. Продавець протягом одного робочого дня (з понеділка по п'ятницю з 9:00 до 18:00) повідомляє Покупця про одержання Замовлення і погоджує додаткову інформацію за обраним товаром та умови доставки.</p>
            <p>2.3. Продавець гарантує відповідність зовнішнього вигляду замовлених виробів їх зразкам у каталозі. Вага виробу, вага і розмір вставок у виробі може незначною мірою відрізнятися від вказаного в каталозі Продавця, зважаючи на особливості технологічного процесу виготовлення ювелірних виробів. </p>
            <p>2.4. Продавець гарантує відповідність якості виробів стандартам України, що пред'являються до ювелірних виробів.</p>
            <p>2.5. Покупець зобов'язується правильно і повністю вказати в заявці контактний номер телефону, електронну пошту та свою адресу.</p>
            <p>2.6. Датою оформлення Замовлення є дата заповнення і направлення Покупцем електронної форми Замовлення на сайті SICVOLO.COM.</p>
            <p>2.7. Терміни доставки визначаються індивідуально для кожного замовлення в залежності від наявності необхідного товару на складі Продавця, умов доставки й місця перебування Покупця.</p>
            <p>2.8. Гарантія на ювелірні вироби та аксесуари для одягу, що були придбані в інтернет-магазині SICVOLO.COM є безстроковою за умови повного додержання правил експлуатації за сторони Покупця.</p>
            <p>2.9. Вся інформація, надана Покупцем Продавцю, є конфіденційною, за винятком письмового дозволу Покупця на використання цієї інформації та випадків, коли цього вимагає українське або міжнародне законодавство і/або органи влади з дотриманням законної процедури.</p>
            <p>2.10. Приймаючи умови цієї Угоди, Покупець, відповідно до Закону України «Про захист персональних даних» № 2297-VI від 01.06.2010, підтверджує згоду на добровільну передачу та обробку його персональних даних Інтернет-магазину SICVOLO.COM з метою виконання його замовлень. Покупець дозволяє Інтернет-магазину SICVOLO.COM передавати його персональні дані третім особам (служби доставки, комерційні партнери) для реалізації його замовлень. Покупець дозволяє використання його персональних даних для отримання рекламних та спеціальних пропозицій, а також внесення його персональних даних до бази даних на необмежений час зберігання.</p>
            <p>2.11. Ювелірні вироби належної якості обміну та поверненню не підлягають відповідно до статті 9 Закону України «Про захист прав споживачів» від 12.05.1991 року № 1023-XII та Постанови Кабінету Міністрів України від 19.03.1994 року № 172 «Про реалізацію окремих положень Закону України «Про захист прав споживачів» (Додаток № 3 до Постанови Кабінету Міністрів України від 19 березня 1994 р. № 172 «Перелік товарів належної якості, що не підлягають обміну, поверненню»).</p>
            <p>2.12. Для забезпечення ремонту виробів в гарантійний термін, вказаний в п.2.8., Покупець має звернутися до Продавця за телефоном або електронною поштою, вказаною на сайті SICVOLO.COM в розділі Контакти. Гарантійний ремонт виробів з вставками із дорогоцінних каменів обов’язково відправляються на експертизу (строком до 10-14 днів). Вироби, що мають сліди механічного пошкодження або неправильного використання (перебування в басейні, взаємодія з лужними миючими засобами, засобами, що містять хлор і йод, кремами і мазями, що містять ртуть і її сполуки, а також в результаті впливу інших зовнішніх факторів), ремонту в гарантійний термін не підлягають.</p>
          
        </div>
      </div>


      <div className={styles.policy_item}>
        <p className={styles.policy_item_title}>3. Здійснення оплати</p>
        <div className={styles.policy_item_text}>
            <p>3.1. Покупець зобов'язується оплатити повну вартість замовленого Товару.</p>
            <p>3.2. Розрахунок з Покупцем проводитися в безготівковій формі (із застосуванням платіжних шлюзів) шляхом заповнення форми для оплати з надсилання розрахункового документа встановленої форми на електронну пошту Покупця.</p>
            <p>3.3. Замовлення від Покупця вважається прийнятим Покупцем тільки після оплати товару у повному обсязі.</p>
        </div>
      </div>

      <div className={styles.policy_item}>
        <p className={styles.policy_item_title}>4. Прикінцеві положення</p>
        <div className={styles.policy_item_text}>
            <p>4.1. Питання, не врегульовані цією Угодою, підлягають вирішенню відповідно до чинного законодавства України.</p>
            <p>4.2. Всі можливі суперечки, які можуть виникнути з цієї Угоди, підлягають вирішенню відповідно до чинного законодавства України.</p>
        </div>
      </div>
      <div className={styles.policy_item}>
        <p className={styles.policy_item_title}>5. Реквізити продавця</p>
        <div className={styles.policy_item_text}>
            <p>ФОП Березовський О.В.</p>
            <p>Юридична адреса: м. Київ, вул. Кащенка Академіка, будинок 18</p>
            {/* <p>Интернет-магазин ювелирных украшений и аксессуаров для одежды SICVOLO (далее "Продавец"), электронный адрес <a href="mailto:www.sicvolo.com">www.sicvolo.com</a> , вы (далее "Покупатель") соглашаетесь на следующее:</p> */}
        </div>
      </div>
      </>)}
    
      

      {i18n.language === "ru" && (<>
        <h1 >Договор публичной оферты</h1>
      <p>Интернет-магазин ювелирных украшений и аксессуаров для одежды SICVOLO (далее "Продавец"), электронный адрес www.sicvolo.com, вы (далее "Покупатель") соглашаетесь на следующее:</p>
    


      <div className={styles.policy_item}>
        <p className={styles.policy_item_title}>1. Общие положения</p>
        <div className={styles.policy_item_text}>
            <p>1.1. Осуществляя заказ в интернет-магазине Продавца, Покупатель принимает и обязуется соблюдать условия, изложенные в этом Договоре. </p>
            <p>1.2. Настоящий Договор является публичной офертой. </p>
            <p>1.3. Покупатель, оформляя заказ на сайте SICVOLO.COM, подтверждает и соглашается с условиями настоящего Договора. </p>
            <p>1.4. Данный Договор может быть изменен односторонне Продавцом полностью или частично в любое время без какого-либо отдельного уведомления. Новая редакция Договора вступит в силу с момента публикации новой редакции на сайте. </p>
            <p>1.5. Интернет-магазин SICVOLO.COM содержит объекты интеллектуальной собственности: фотографии, видеоматериалы, графические изображения, эксклюзивный дизайн ювелирных изделий. Покупатель соглашается не совершать действия, нарушающие действующее законодательство Украины, в том числе в сфере интеллектуальной собственности, авторских и/или смежных прав, а также любые другие действия, которые могут привести к нарушению нормальной работы сайта и сервисов сайта. </p>
            <p>1.6. Все товарные знаки и наименования, на которые есть ссылки в материалах этого сайта, являются собственностью Продавца. </p>
            <p>1.7. Покупателю, которому не исполнилось 18 лет, необходимо получить согласие родителей или других законных представителей на оформление заказа. Если родители или другие законные представители Покупателя не согласны с каким-либо условием настоящего Договора, Покупатель обязан отказаться от оформления заказа на сайте Продавца.</p>
        </div>
      </div>

      <div className={styles.policy_item}>
        <p className={styles.policy_item_title}>2. Условия оформления заказа товара</p>
        <div className={styles.policy_item_text}>
            <p>2.1. Покупатель выбирает ювелирные изделия или аксессуары для одежды (Товар) из каталога SICVOLO.COM, оформляет Заказ путем заполнения электронной формы на сайте и направляет его Продавцу</p>
            <p>2.2. Продавец в течение одного рабочего дня (с понедельника по пятницу с 9:00 до 18:00) сообщает Покупателю о получении Заказа и согласовывает дополнительную информацию по выбранному товару и условия доставки. </p>
            <p>2.3. Продавец гарантирует соответствие внешнего вида заказанных изделий их образцам в каталоге. Вес изделия, вес и размер вставок в изделии может незначительно отличаться от указанного в каталоге Продавца, учитывая особенности технологического процесса изготовления ювелирных изделий. </p>
            <p>2.4. Продавец гарантирует соответствие качества изделий стандартам Украины, предъявляемым к ювелирным изделиям. </p>
            <p>2.5. Покупатель обязуется правильно и полностью указать в заявке контактный номер телефона, электронную почту и свой адрес. </p>
            <p>2.6. Датой оформления Заказа является дата заполнения и направления Покупателем электронной формы Заказа на сайте SICVOLO.COM. </p>
            <p>2.7. Сроки доставки определяются индивидуально для каждого заказа в зависимости от наличия необходимого товара на складе Продавца, условий доставки и местонахождения Покупателя. </p>
            <p>2.8. Гарантия на ювелирные изделия, приобретенные в интернет-магазине SICVOLO.COM, является бессрочной при условии полного соблюдения правил эксплуатации со стороны Покупателя. </p>
            <p>2.9. Вся информация, предоставленная Покупателем Продавцу, является конфиденциальной, за исключением письменного разрешения Покупателя на использование этой информации и случаев, когда это требует украинское или международное законодательство и/или органы власти в соответствии с законной процедурой. </p>
            <p>2.10. Принимая условия настоящего Договора, Покупатель в соответствии с Законом Украины "О защите персональных данных" № 2297-VI от 01.06.2010 года, подтверждает согласие на добровольную передачу и обработку своих персональных данных Интернет-магазином SICVOLO.COM с целью выполнения его заказов. Покупатель разрешает Интернет-магазину SICVOLO.COM передавать его персональные данные третьим лицам (службы доставки, коммерческие партнеры) для реализации его заказов. Покупатель разрешает использование его персональных данных для получения рекламных и специальных предложений, а также включение его персональных данных в базу данных на неограниченный срок хранения. </p>
            <p>2.11. Ювелирные изделия надлежащего качества не подлежат обмену и возврату в соответствии со статьей 9 Закона Украины "О защите прав потребителей" от 12.05.1991 года № 1023-XII и Постановлением Кабинета Министров Украины от 19.03.1994 года № 172 "О реализации отдельных положений Закона Украины "О защите прав потребителей" (Приложение № 3 к Постановлению Кабинета Министров Украины от 19 марта 1994 года № 172 "Перечень товаров надлежащего качества, не подлежащих обмену, возврату"). </p>
            <p>2.12. Для обеспечения ремонта изделий в гарантийный срок, указанный в п.2.8., Покупатель должен обратиться к Продавцу по телефону или электронной почте, указанной на сайте SICVOLO.COM в разделе Контакты. Гарантийный ремонт изделий с вставками из драгоценных камней обязательно направляются на экспертизу (сроком до 10-14 дней). Изделия, имеющие следы механического повреждения или неправильного использования (нахождение в бассейне, взаимодействие с щелочными моющими средствами, средствами, содержащими хлор и йод, кремами и мазями, содержащими ртуть и ее соединения, а также вследствие воздействия других внешних факторов), ремонту в гарантийный срок не подлежат.</p>
          
        </div>
      </div>


      <div className={styles.policy_item}>
        <p className={styles.policy_item_title}>3. Осуществление оплаты</p>
        <div className={styles.policy_item_text}>
            <p>3.1. Покупатель обязуется оплатить полную стоимость заказанного Товара. </p>
            <p>3.2. Расчет с Покупателем производится в безналичной форме (с применением платежных шлюзов) путем заполнения формы для оплаты с отправкой расчетного документа установленной формы на электронную почту Покупателя. </p>
            <p>3.3. Заказ от Покупателя считается принятым Покупателем только после оплаты товара в полном объеме.</p>
        </div>
      </div>

      <div className={styles.policy_item}>
        <p className={styles.policy_item_title}>4. Заключительные положения</p>
        <div className={styles.policy_item_text}>
            <p>4.1. Вопросы, не урегулированные настоящим Договором, подлежат разрешению в соответствии с действующим законодательством Украины. </p>
            <p>4.2. Все возможные споры, которые могут возникнуть из настоящего Договора, подлежат разрешению в соответствии с действующим законодательством Украины.</p>
        </div>
      </div>
      <div className={styles.policy_item}>
        <p className={styles.policy_item_title}>5. Реквизиты продавца</p>
        <div className={styles.policy_item_text}>
            <p>ФОП Березовский О.В.</p>
            <p>Юридический адрес: г. Киев, ул. Кащенка Академика, дом 18</p>
            {/* <p>Интернет-магазин ювелирных украшений и аксессуаров для одежды SICVOLO (далее "Продавец"), электронный адрес <a href="mailto:www.sicvolo.com">www.sicvolo.com</a> , вы (далее "Покупатель") соглашаетесь на следующее:</p> */}
        </div>
      </div>
      </>)}

    
    </div>
    
        </>
   
  )
}
