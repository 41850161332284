export const ONLY_BUCKLE_TYPE = 'Only_buckle';

export const beltSizes = [65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115];
export const optionsRings = [
  { value: 18.15, label: '18.15 mm' },
  { value: 18.79, label: '18.79 mm' },
  { value: 19.11, label: '19.11 mm' },
  { value: 19.43, label: '19.43 mm' },
  { value: 19.75, label: '19.75 mm' },
  { value: 20.06, label: '20.06 mm' },
  { value: 20.38, label: '20.38 mm' },
  { value: 20.7, label: '20.70 mm' },
  { value: 21.02, label: '21.02 mm' },
  { value: 21.34, label: '21.34 mm' },
  { value: 21.66, label: '21.66 mm' },
  { value: 21.97, label: '21.97 mm' },
  { value: 22.29, label: '22.29 mm' },
  { value: 22.61, label: '22.61 mm' },
  { value: 22.93, label: '22.93 mm' },
];
export const wSizesRings = [
  { value: 15.92, label: '15.92 mm' },
  { value: 16.24, label: '16.24 mm' },
  { value: 16.56, label: '16.56 mm' },
  { value: 16.88, label: '16.88 mm' },
  { value: 17.2, label: '17.20 mm' },
  { value: 17.52, label: '17.52 mm' },
  { value: 17.83, label: '17.83 mm' },
  { value: 18.15, label: '18.15 mm' },
  { value: 18.47, label: '18.47 mm' },
];
