export function getUniqueTitles(items) {
  const unique = [];
  const seen = new Set();

  for (const item of items) {
    if (!seen.has(item.title)) {
      unique.push(item);
      seen.add(item.title);
    }
  }

  return unique;
}
