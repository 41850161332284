import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  items: JSON.parse(localStorage.getItem('cart')) || [],
  showCardPopup: false,
  total: JSON.parse(localStorage.getItem('total')) || 0,
  totalWithoutPromo: JSON.parse(localStorage.getItem('totalWithoutPromo')) || 0,
  promoSale: 0,
  finalTotal: JSON.parse(localStorage.getItem('finalTotal')) || 0,
};

export const cardSlice = createSlice({
  name: 'card',
  initialState,
  reducers: {
    showCard: (state, action) => {
      state.showCardPopup = action.payload;
    },
    setPromoSale: (state, action) => {
      state.promoSale = action.payload;

      let total = 0;
      let finalTotal = 0;
      let totalWithoutPromo = 0;

      state.items.forEach((item) => {
        const { regular_price, price, discount, qty } = item;

        totalWithoutPromo += regular_price ? regular_price * qty : price * qty;
        total += price * qty;

        const promoDiscount =
          (state.promoSale / 100) * (regular_price || price);

        if (state.promoSale > discount) {
          finalTotal += (regular_price - promoDiscount) * qty;
        } else {
          finalTotal += price * qty;
        }
      });

      state.total = Math.round(total);
      state.finalTotal = Math.round(finalTotal);
      state.totalWithoutPromo = Math.round(totalWithoutPromo);
      localStorage.setItem('finalTotal', JSON.stringify(state.finalTotal));
      localStorage.setItem(
        'totalWithoutPromo',
        JSON.stringify(state.totalWithoutPromo)
      );
    },
    addToCart: (state, action) => {
      state.items.push({
        ...action.payload,
        cartItemId: uuidv4(),
      });

      let total = 0;
      let finalTotal = 0;
      let totalWithoutPromo = 0;

      state.items.forEach((item) => {
        const { regular_price, price, discount, qty } = item;

        totalWithoutPromo += regular_price ? regular_price * qty : price * qty;
        total += price * qty;

        const promoDiscount =
          (state.promoSale / 100) * (regular_price || price);

        if (state.promoSale > discount) {
          finalTotal += (regular_price - promoDiscount) * qty;
        } else {
          finalTotal += price * qty;
        }
      });

      state.total = Math.round(total);
      state.finalTotal = Math.round(finalTotal);
      state.totalWithoutPromo = Math.round(totalWithoutPromo);
      localStorage.setItem('finalTotal', JSON.stringify(state.finalTotal));
      localStorage.setItem(
        'totalWithoutPromo',
        JSON.stringify(state.totalWithoutPromo)
      );
      localStorage.setItem('total', JSON.stringify(state.total));
      localStorage.setItem('cart', JSON.stringify(state.items));
    },
    removeItem: (state, action) => {
      state.items = state.items.filter(
        (obj) => obj.cartItemId !== action.payload
      );

      let total = 0;
      let finalTotal = 0;
      let totalWithoutPromo = 0;

      state.items.forEach((item) => {
        const { regular_price, price, discount, qty } = item;

        totalWithoutPromo += regular_price ? regular_price * qty : price * qty;
        total += price * qty;

        const promoDiscount =
          (state.promoSale / 100) * (regular_price || price);

        if (state.promoSale > discount) {
          finalTotal += (regular_price - promoDiscount) * qty;
        } else {
          finalTotal += price * qty;
        }
      });

      state.total = Math.round(total);
      state.finalTotal = Math.round(finalTotal);
      state.totalWithoutPromo = Math.round(totalWithoutPromo);
      localStorage.setItem('cart', JSON.stringify(state.items));
      localStorage.setItem('total', JSON.stringify(state.total));
      localStorage.setItem('finalTotal', JSON.stringify(state.finalTotal));
      localStorage.setItem(
        'totalWithoutPromo',
        JSON.stringify(state.totalWithoutPromo)
      );
    },
    plusItem: (state, action) => {
      const findItem = state.items.find(
        (obj) => obj.cartItemId === action.payload
      );
      if (findItem) {
        findItem.qty++;
      }
      let total = 0;
      let finalTotal = 0;
      let totalWithoutPromo = 0;

      state.items.forEach((item) => {
        const { regular_price, price, discount, qty } = item;

        totalWithoutPromo += regular_price ? regular_price * qty : price * qty;
        total += price * qty;

        const promoDiscount =
          (state.promoSale / 100) * (regular_price || price);

        if (state.promoSale > discount) {
          finalTotal += (regular_price - promoDiscount) * qty;
        } else {
          finalTotal += price * qty;
        }
      });

      state.total = Math.round(total);
      state.finalTotal = Math.round(finalTotal);
      state.totalWithoutPromo = Math.round(totalWithoutPromo);
      localStorage.setItem('cart', JSON.stringify(state.items));
      localStorage.setItem('total', JSON.stringify(state.total));
      localStorage.setItem('finalTotal', JSON.stringify(state.finalTotal));
      localStorage.setItem(
        'totalWithoutPromo',
        JSON.stringify(state.totalWithoutPromo)
      );
    },
    minusItem: (state, action) => {
      const findItem = state.items.find(
        (obj) => obj.cartItemId === action.payload
      );
      if (findItem && findItem.qty > 0) {
        findItem.qty--;
      }

      let total = 0;
      let finalTotal = 0;
      let totalWithoutPromo = 0;

      state.items.forEach((item) => {
        const { regular_price, price, discount, qty } = item;

        totalWithoutPromo += regular_price ? regular_price * qty : price * qty;
        total += price * qty;

        const promoDiscount =
          (state.promoSale / 100) * (regular_price || price);

        if (state.promoSale > discount) {
          finalTotal += (regular_price - promoDiscount) * qty;
        } else {
          finalTotal += price * qty;
        }
      });

      state.total = Math.round(total);
      state.finalTotal = Math.round(finalTotal);
      state.totalWithoutPromo = Math.round(totalWithoutPromo);
      localStorage.setItem('cart', JSON.stringify(state.items));
      localStorage.setItem('total', JSON.stringify(state.total));
      localStorage.setItem('finalTotal', JSON.stringify(state.finalTotal));
      localStorage.setItem(
        'totalWithoutPromo',
        JSON.stringify(state.totalWithoutPromo)
      );
    },
  },
});

export const {
  showCard,
  addToCart,
  removeItem,
  plusItem,
  minusItem,
  setPromoSale,
} = cardSlice.actions;

export default cardSlice.reducer;
